<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div
            class="theme-design-ideas-page"
            v-if="Object.keys(themeDesignIdeas.payload).length > 0"
        >
            <BreadCrumbs :current="themeDesignIdeas.payload.name + ' Design'" />
            <DisplayBanner img="eclectic_design.png">
                <div class="wrapper">
                    <Hexagon
                        :t1="
                            `${themeDesignIdeas.payload.name.toUpperCase()} DESIGN`
                        "
                    />
                </div>
            </DisplayBanner>

            <div class="content-container">
                <div class="theme-description">
                    <div class="social-sharing">
                        <p>Share on</p>
                        <div class="networks">
                            <ShareNetwork
                                network="facebook"
                                :url="currentUrl"
                                :title="
                                    `${themeDesignIdeas.payload.name.toUpperCase()} DESGIN`
                                "
                                :description="
                                    themeDesignIdeas.payload.description
                                "
                                hashtags="interior, designer"
                                :quote="themeDesignIdeas.payload.description"
                            >
                                <span>
                                    <font-awesome-icon
                                        :icon="['fab', 'facebook']"
                                    />
                                </span>
                            </ShareNetwork>
                            <!-- <ShareNetwork
                            network="instagram"
                            :url="currentUrl"
                            :title="
                                `${themeDesignIdeas.name.toUpperCase()} DESGIN`
                            "
                            :description="themeDesignIdeas.description"
                            hashtags="interior, designer"
                            class="sharing-tag"
                        >
                            <span>
                                <font-awesome-icon
                                    :icon="['fab', 'instagram']"
                                />
                            </span>
                        </ShareNetwork> -->
                        </div>
                    </div>
                    <HeadAndSubTexts
                        :subHeading2="themeDesignIdeas.payload.description"
                    />
                </div>

                <div
                    class="featured-showcases-container"
                    v-if="themeDesignIdeas.payload.featured.length > 0"
                >
                    <HeadAndSubTexts
                        :heading="
                            `Featured ${themeDesignIdeas.payload.name} Showcases`
                        "
                    />
                    <div class="featured-showcases">
                        <div
                            v-for="featured in themeDesignIdeas.payload
                                .featured"
                            :key="featured.id"
                        >
                            <a :href="featured.link" target="_blank">
                                <OverLayCard
                                    alignment="center"
                                    ovalOverlay
                                    :title="featured.floorplan"
                                    :description="featured.description"
                                    :img="featured.image.n_image"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="theme-design-ideas"
                    v-if="themeDesignIdeas.payload.designs.length > 0"
                >
                    <HeadAndSubTexts
                        :heading="
                            `${themeDesignIdeas.payload.name} Design Ideas`
                        "
                    />
                    <div v-if="windowWidth < 922">
                        <ImageGroup
                            :designs="designsArrayChunk[0]"
                            layoutType="5"
                            useBy="theme"
                        />
                    </div>
                    <div v-else>
                        <ImageGroup
                            :designs="designsArrayChunk[0]"
                            layoutType="1"
                            useBy="theme"
                        />
                    </div>

                    <StyleBox
                        class="style-quiz-banner"
                        leftBanner
                        img="decoration-2373342_1920.png"
                        heading="Unsure About Your Decor Style?"
                    />

                    <div v-if="windowWidth < 922">
                        <ImageGroup
                            :designs="designsArrayChunk[1]"
                            layoutType="5"
                            useBy="theme"
                        />
                    </div>
                    <div v-else>
                        <ImageGroup
                            :designs="designsArrayChunk[1]"
                            layoutType="2"
                            useBy="theme"
                        />
                    </div>
                </div>

                <div
                    class="theme-products"
                    v-if="themeDesignIdeas.payload.products.length > 0"
                >
                    <HeadAndSubTexts
                        :heading="
                            `Product that fit in ${themeDesignIdeas.payload.name}`
                        "
                    />
                    <Carousel :settings="settings">
                        <div
                            class="cards"
                            v-for="product in themeDesignIdeas.payload.products"
                            :key="product.id"
                        >
                            <router-link
                                :to="{
                                    name: 'ProductDetailsPage',
                                    params: { id: product.product_id },
                                }"
                            >
                                <Cards
                                    class="card"
                                    :cardname="product.name"
                                    :img="product.image_meta.p_image"
                                />
                            </router-link>
                        </div>
                    </Carousel>
                    <!-- <div class="products" v-if="width<922">
                    <div
                        class="cards"
                        v-for="product in themeDesignIdeas.products"
                        :key="product.id"
                    >
                        <router-link
                            :to="{
                                name: 'ProductDetailsPage',
                                params: { id: product.product_id },
                            }"
                        >
                            <Cards
                                class="card"
                                :cardname="product.name"
                                :img="product.image_meta.p_image"
                            />
                        </router-link>
                    </div>
                </div> -->
                </div>
                <!-- <div
                class="top-designers-container"
                v-if="themeDesignIdeas.designers.length > 0"
            >
                <HeadAndSubTexts heading="Top Eclectic Designers" />
                <div class="top-designers">
                    <div
                        v-for="designer in themeDesignIdeas.designers"
                        :key="designer.id"
                    >
                        <router-link
                            :to="{
                                name: 'DesignerDetailsPage',
                                params: { id: designer.id },
                            }"
                        >
                            <CircularImage
                                :img="designer.image_meta.s_image"
                                :name="designer.name"
                            />
                        </router-link>
                    </div>
                </div>
            </div> -->

                <!-- <StyleBox
                class="style-quiz-banner"
                leftBanner
                img="styleQuizPhoto3.png"
                heading="Unsure About your Style ?"
            /> -->
            </div>

            <Footer bannerType="center" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ThemeDesignIdeasPageModule from 'store/modules/ThemeDesignIdeasPage'

import DisplayBanner from 'componentsv2/DisplayBanner'
import Hexagon from 'componentsv2/Hexagon'
import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import ImageGroup from 'componentsv2/ImageGroup'
import OverLayCard from 'componentsv2/OverLayCard'
import StyleBox from 'componentsv2/StyleBox'
import Carousel from 'componentsv2/Carousel'
import Cards from 'componentsv2/Cards'
//import CircularImage from 'componentsv2/CircularImage'
import BreadCrumbs from 'componentsv2/BreadCrumbs'
import Loader from 'componentsv2/Loader'
import Footer from 'componentsv2/Footer'
import loaderHandler from 'mixins/loader'

export default {
    name: 'ThemeDesignIdeasPage',
    components: {
        DisplayBanner,
        Hexagon,
        HeadAndSubTexts,
        ImageGroup,
        OverLayCard,
        Carousel,
        Cards,
        StyleBox,
        Loader,
        BreadCrumbs,
        // CircularImage,
        Footer,
    },
    mixins: [RegisterStoreModule, loaderHandler],
    data() {
        return {
            currentUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_BASE_URL_B2B
                    : process.env.VUE_APP_BASE_URL + this.$route.path.slice(1),
            settings: {
                arrows: true,
                prevArrow: false,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 3,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 922,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                ],
            },
        }
    },
    computed: {
        ...mapState({
            themeDesignIdeas: state =>
                state.ThemeDesignIdeasPage.themeDesignIdeas,
            windowWidth: state => state.AppModule.windowWidth,
        }),

        designsArrayChunk() {
            return this.themeDesignIdeas.payload.designs.reduce(
                (resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / 6)
                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []
                    }
                    resultArray[chunkIndex].push(item)
                    return resultArray
                },
                [],
            )
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
        // setTimeout(() => {
        //     this.loaderShow = false
        // }, 1000)
    },
    metaInfo: function() {
        return {
            title: 'SILOHO',
            titleTemplate: '%s | Theme design ideas',
        }
    },
    created() {
        document.querySelector('body').style.overflow = 'hidden'
        this.registerStoreModule(
            'ThemeDesignIdeasPage',
            ThemeDesignIdeasPageModule,
        )
        this.fetchThemeDesignIdeas(this.$route.params.id)
            .then(response => {
                if (response.responseCode == 200) {
                    document.querySelector(
                        "meta[property='og:title']",
                    ).content = `${this.themeDesignIdeas.payload.name} Design`

                    document.querySelector(
                        "meta[property='og:description']",
                    ).content = `${this.themeDesignIdeas.payload.description}`
                }
                setTimeout(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                }, 100)
                //  else {
                //     this.$router.push({ name: '404' })
                // }
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                }, 1000)
            })
    },
    destroyed() {
        this.$store.unregisterModule('ThemeDesignIdeasPage')
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        ...mapActions({
            fetchThemeDesignIdeas: 'fetchThemeDesignIdeas',
            onResize: 'onResize',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './ThemeDesignIdeasPage.scss';
</style>
