import { getDesignIdeasByTheme } from 'api/designIdeasApi'


const ThemeDesignIdeasPage = {
    state: () => ({
        themeDesignIdeas: []
    }),

    mutations: {
        setThemeDesignIdeas(state, payload){
            state.themeDesignIdeas = payload
        },
    },

    getters: {},

    actions: {
        fetchThemeDesignIdeas({commit}, theme_id){
            return getDesignIdeasByTheme(theme_id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setThemeDesignIdeas', payload)
                        return payload
                    }
                })
        },
    }
}

export default ThemeDesignIdeasPage;